<template>
  <div
    class="fixed inset-0 overflow-auto bg-gray-500 bg-opacity-75 z-modal flex items-center justify-center"
  >
    <div class="relative mx-auto signup-modal habby-modal-text">
      <div
        class="relative bg-white shadow-lg text-gray-900 z-50 signup-h"
        @click.stop
      >
        <div class="modal-overlay"></div>
        <div class="p-3">
          <div class="flex justify-end">
            <!-- <div class="col-10">
                <p class="font-bold inline-flex items-center   habby-modal-title">
                    Habby AI
                </p> 
              </div> -->
            <div class="flex justify-end z-50">
              <div
                @click="closeElementsModal"
                class="cursor-pointer flex items-center justify-center bg-white p-2 rounded-full"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="#111827"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="font-inter flex flex-col signup-div relative z-50">
            <img
              class="mt-4 mb-5 image-header"
              :src="require(`images/logo-icon.png`)"
            />

            <div>
              <h3 class="font-bold head-signup">
                Welcome to the future of coaching!
              </h3>
              <p class="font-signup bullet-margin">
                The best mobile experience is on the Coach Catalyst app where
                you can:
              </p>
            </div>

            <div class="inline-flex bullet-margin items-center font-signup">
              <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33333 11H7.345M12 11H12.0117M16.6667 11H16.6783M22.5 11C22.5 16.1547 17.799 20.3333 12 20.3333C10.2042 20.3333 8.51367 19.9326 7.03545 19.2262L1.5 20.3333L3.12749 15.9934C2.09682 14.5494 1.5 12.8367 1.5 11C1.5 5.84534 6.20101 1.66667 12 1.66667C17.799 1.66667 22.5 5.84534 22.5 11Z"
                  stroke="#3AB574"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <p class="ml-2">Send and receive messages</p>
            </div>

            <div class="inline-flex bullet-margin items-center font-signup">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8333 23.3333H25.6667V21C25.6667 19.067 24.0997 17.5 22.1667 17.5C21.0518 17.5 20.0587 18.0213 19.4177 18.8334M19.8333 23.3333H8.16668M19.8333 23.3333V21C19.8333 20.2344 19.6858 19.5032 19.4177 18.8334M8.16668 23.3333H2.33334V21C2.33334 19.067 3.90035 17.5 5.83334 17.5C6.94822 17.5 7.94134 18.0213 8.5823 18.8334M8.16668 23.3333V21C8.16668 20.2344 8.31417 19.5032 8.5823 18.8334M8.5823 18.8334C9.44243 16.6845 11.544 15.1667 14 15.1667C16.4561 15.1667 18.5576 16.6845 19.4177 18.8334M17.5 8.16667C17.5 10.0997 15.933 11.6667 14 11.6667C12.067 11.6667 10.5 10.0997 10.5 8.16667C10.5 6.23367 12.067 4.66667 14 4.66667C15.933 4.66667 17.5 6.23367 17.5 8.16667ZM24.5 11.6667C24.5 12.9553 23.4553 14 22.1667 14C20.878 14 19.8333 12.9553 19.8333 11.6667C19.8333 10.378 20.878 9.33333 22.1667 9.33333C23.4553 9.33333 24.5 10.378 24.5 11.6667ZM8.16668 11.6667C8.16668 12.9553 7.12201 14 5.83334 14C4.54468 14 3.50001 12.9553 3.50001 11.6667C3.50001 10.378 4.54468 9.33333 5.83334 9.33333C7.12201 9.33333 8.16668 10.378 8.16668 11.6667Z"
                  stroke="#3AB574"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="ml-2">Add/comment on Community posts</p>
            </div>

            <div class="inline-flex bullet-margin items-center font-signup">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 9.33333V18.6667"
                  stroke="#3AB574"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.6667 14H9.33334"
                  stroke="#3AB574"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 24.5V24.5C8.2005 24.5 3.5 19.7995 3.5 14V14C3.5 8.2005 8.2005 3.5 14 3.5V3.5C19.7995 3.5 24.5 8.2005 24.5 14V14C24.5 19.7995 19.7995 24.5 14 24.5Z"
                  stroke="#3AB574"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <p class="ml-2">Assign actions and workouts</p>
            </div>
          </div>
          <div class="text-center button-sign-up justify-items-center">
            <button
              onclick="window.location.href = 'https://links.coachcatalyst.com/cc_app';"
              class="font-inter button-sign font-semibold inline-flex items-center justify-center border border-transparent rounded-md shadow-sm text-white theme-color"
            >
              Download the app now
            </button>

            <p class="font-bottom" @click="closeElementsModal">
              I understand this page isn’t mobile optimized
            </p>
          </div>

          <div class="absolute top-0 right-0">
            <img
              :src="require(`images/header.png`)"
              style="width: 224px; height: 273px"
              alt="CC Login Image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //   import UserAvatar from "../communities/shared/user-avatar";
  import ApiUrls from '../../api-url';
  import AuthenticationMixin from '../../authentication-mixin';

  export default {
    name: 'after-signup',
    mixins: [ApiUrls, AuthenticationMixin],

    methods: {
      closeElementsModal() {
        window.location.href = '/clients';
      },
    },
  };
</script>
