<template>
  <div>
    <UpperNavbar></UpperNavbar>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <ProgressBar></ProgressBar>
      </div>
      <div class="col-md-8 col-sm-12">
        <div class="info-form-div">
          <div class="new-personal-info main-body">
            <p class="auth-p">Help Us Set Up Your Account</p>
            <form
              novalidate="novalidate"
              class="simple_form new_user"
              accept-charset="UTF-8"
              method="post"
            >
              <input name="utf8" type="hidden" value="✓" />
              <fieldset>
                <div>
                  <label>I am a</label>
                  <select
                    v-model="form.signup_coach_type"
                    tabindex="1"
                    class="personal-info-select"
                  >
                    <option value="">Coach Type</option>
                    <option v-for="obj in coachTypes" v-bind:value="obj">
                      {{ obj }}
                    </option>
                  </select>
                </div>

                <div class="d-flex">
                  <div>
                    <label>I work with</label>
                    <select
                      v-model="form.signup_teammates"
                      style="width: 160px"
                      tabindex="1"
                      class="personal-info-select"
                    >
                      <option value=""># of teammates</option>
                      <option v-for="obj in teamMates" v-bind:value="obj">
                        {{ obj }}
                      </option>
                    </select>
                  </div>

                  <div>
                    <label class="pl-4 pr-4">and</label>
                    <select
                      v-model="form.signup_clients"
                      tabindex="1"
                      class="personal-info-select responsive-select"
                    >
                      <option value=""># of clients</option>
                      <option v-for="obj in numberOfClients" v-bind:value="obj">
                        {{ obj }}
                      </option>
                    </select>
                  </div>
                </div>

                <div>
                  <label>My work is done</label>
                  <select
                    v-model="form.signup_location"
                    tabindex="1"
                    class="personal-info-select"
                  >
                    <option value="">location</option>
                    <option v-for="obj in locations" v-bind:value="obj">
                      {{ obj }}
                    </option>
                  </select>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div class="info-footer">
          <input
            type="button"
            @click="submit"
            value="Next"
            tabindex="4"
            class="btn button info-next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import ApiUrls from '../../api-url';
  import { mapGetters, mapState } from 'vuex';
  import getters from '../../stores/login-store/getters/login-getters';
  import AuthenticationMixin from '../../authentication-mixin';
  import ProgressBar from '../shared/progress-bar';
  import UpperNavbar from '../shared/upper-navbar';

  export default {
    components: { ProgressBar, UpperNavbar },
    mixins: [ApiUrls, AuthenticationMixin],
    data() {
      return {
        form: {
          signup_coach_type: '',
          signup_teammates: '',
          signup_clients: '',
          signup_location: '',
        },
        coachTypes: [
          'Fitness Coach',
          'Life Coach',
          'Nutrition Coach',
          'Health Coach',
          'Business Coach',
          'Other Coach',
        ],
        teamMates: ['no teammates', '1-5 teammates', '5+ teammates'],
        numberOfClients: [
          'No clients yet',
          '1-10 clients',
          '10-50 clients',
          '50+ clients',
        ],
        locations: ['in person and online', 'in person', 'online'],
      };
    },
    beforeCreate: function () {
      document.body.style.backgroundColor = '#FFFFFF';
    },
    async created() {
      await this.get_info();
    },
    mounted() {
      let content_margin =
        document.getElementsByClassName('content-margins')[0];
      let user_header = document.getElementsByClassName('user-header')[0];
      if (content_margin) content_margin.removeAttribute('class');
      if (user_header) user_header.removeAttribute('class');
    },
    computed: {
      ...mapState([
        'trialAmount',
        'trialDays',
        'urlParam',
        'currentPlan',
        'accountType',
      ]),
      ...mapGetters(['LOGIN_FORM', 'SIGN_UP_BUSINESS_INFO']),
    },
    methods: {
      async get_info() {
        let response = await axios.get(
          this.organizationUrl + 'get_signup_info'
        );
        if (response.data.success) {
          this.form.signup_coach_type = response.data.result.signup_coach_type
            ? response.data.result.signup_coach_type
            : '';
          this.form.signup_teammates = response.data.result.signup_teammates
            ? response.data.result.signup_teammates
            : '';
          this.form.signup_clients = response.data.result.signup_clients
            ? response.data.result.signup_clients
            : '';
          this.form.signup_location = response.data.result.signup_location
            ? response.data.result.signup_location
            : '';
          if (response.data.result.plan) {
            this.$store.commit(
              'setUrlParam',
              response.data.result.plan.id === 14
                ? 'webinarUnlimited'
                : 'webinar75'
            );
          }
        }
      },
      async submit() {
        var response = await axios.post(
          this.organizationUrl + 'signup_personal_info',
          this.form
        );
        if (response.data.success) {
          this.$store.commit(
            'setSelectedStep',
            getters.SIGN_UP_BUSINESS_INFO()
          );
        } else {
          this.$toast.show('Error updating org.', this.toasted_options.error);
        }
      },
    },
  };
</script>
