<template>
  <div>
    <UpperNavbar></UpperNavbar>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <ProgressBar></ProgressBar>
      </div>
      <div class="col-md-8 col-sm-12">
        <div class="info-form-div">
          <div class="content-margins new-personal-info main-body">
            <p class="font-semibold text-2xl">How can we help you best?</p>
            <div class="mb-5 mt-8 flex flex-col">
              <label
                >Whats the number one problem you’re hoping to solve with Coach
                Catalyst?</label
              >
              <textarea
                tabindex="1"
                style="resize: none; border-radius: 15px; margin-top: 12px"
                type="text"
                v-model="form.signup_get_started"
                rows="6"
                class="shadow-sm"
                placeholder="Let us know how we can help you..."
              >
              </textarea>
            </div>
          </div>
        </div>
        <div class="info-footer">
          <input
            type="button"
            @click="back"
            value="Back"
            tabindex="4"
            class="btn button back-btn"
          />
          <input
            type="button"
            @click="submit"
            value="Next"
            tabindex="4"
            class="btn button info-next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import ApiUrls from '../../api-url';
  import { mapGetters, mapState } from 'vuex';
  import getters from '../../stores/login-store/getters/login-getters';
  import AuthenticationMixin from '../../authentication-mixin';
  import ProgressBar from '../shared/progress-bar';
  import UpperNavbar from '../shared/upper-navbar';

  export default {
    components: { ProgressBar, UpperNavbar },
    mixins: [ApiUrls, AuthenticationMixin],
    data() {
      return {
        form: {
          signup_coach_type: '',
          signup_teammates: '',
          signup_clients: '',
          signup_location: '',
          signup_get_started: '',
        },
      };
    },
    async created() {
      await this.get_info();
    },
    computed: {
      ...mapState([
        'trialAmount',
        'trialDays',
        'urlParam',
        'currentPlan',
        'accountType',
      ]),
      ...mapGetters(['LOGIN_FORM', 'SIGN_UP_BUSINESS_INFO', 'SEND_YOUR_PHONE']),
    },
    methods: {
      async get_info() {
        let response = await axios.get(
          this.organizationUrl + 'get_signup_info'
        );
        if (response.data.success) {
          this.form.signup_coach_type = response.data.result.signup_coach_type
            ? response.data.result.signup_coach_type
            : '';
          this.form.signup_teammates = response.data.result.signup_teammates
            ? response.data.result.signup_teammates
            : '';
          this.form.signup_clients = response.data.result.signup_clients
            ? response.data.result.signup_clients
            : '';
          this.form.signup_location = response.data.result.signup_location
            ? response.data.result.signup_location
            : '';
          if (response.data.result.plan) {
            this.$store.commit(
              'setUrlParam',
              response.data.result.plan.id === 14
                ? 'webinarUnlimited'
                : 'webinar75'
            );
          }
        }
      },
      async submit() {
        var response = await axios.post(
          this.organizationUrl + 'signup_personal_info',
          this.form
        );
        if (response.data.success) {
          if (this.urlParam || this.superOrg) {
            this.$store.commit('setSelectedStep', getters.SEND_YOUR_PHONE());
          } else {
            if (parseInt(this.trialAmount) > 0) {
              this.$store.commit(
                'setSelectedStep',
                getters.SIGN_UP_PAYMENT_INFO()
              );
            } else {
              this.$store.commit('setSelectedStep', getters.SEND_YOUR_PHONE());
            }
          }
        } else {
          this.$toast.show('Error updating org.', this.toasted_options.error);
        }
      },
      back() {
        this.$store.commit('setSelectedStep', getters.SIGN_UP_BUSINESS_INFO());
      },
    },
  };
</script>
