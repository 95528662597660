<template>
  <div>
    <UpperNavbar></UpperNavbar>
    <div class="flex justify-center items-center flex-col">
      <p class="font-semibold text-2xl text-gray-900 mt-16 mx-auto px-8">
        Our most successful coaches book a call
      </p>
      <p
        style="max-width: 1170px"
        class="text-gray-700 font-normal mt-6 mx-auto px-8"
      >
        We’ve found the coaches who have the most success within their trial
        book a call with our team within the first few days. To make it easy,
        book your call below or choose to explore on your own. You can always
        book a call with our team as you go!
      </p>
      <div style="width: 100%; margin: 0 auto" class="mt-4">
        <iframe
          src="https://api.leadconnectorhq.com/widget/booking/QhVubR0rXofpY2PV3WEl"
          class="px-8"
          style="
            width: 100%;
            border: none;
            overflow: hidden;
            padding-top: 0px;
            height: 900px;
          "
          scrolling="no"
          id="j92UQWxM8qUBQjQF2K2X_1680273001008"
        ></iframe>
      </div>
      <br />
      <!--      <script-->
      <!--        type="application/javascript"-->
      <!--        src="https://api.leadconnectorhq.com/js/form_embed.js"-->
      <!--      ></script>-->
      <div class="center-book">
        <input
          v-if="isThirdPartyScriptLoaded"
          type="button"
          @click="bookLater"
          value="I’ll book my call later"
          tabindex="4"
          class="btn button back-btn border border-black border-solid mb-4"
          style="width: auto !important"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import UpperNavbar from '../shared/upper-navbar';
  import getters from '../../stores/login-store/getters/login-getters';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        isThirdPartyScriptLoaded: false,
      };
    },
    components: { UpperNavbar },
    mounted() {
      const scriptElement = document.getElementById(
        'j92UQWxM8qUBQjQF2K2X_1680273001008'
      );
      scriptElement.addEventListener('load', () => {
        this.isThirdPartyScriptLoaded = true;
      });
    },
    computed: {
      ...mapGetters(['AFTER_SIGNUP']),
    },
    methods: {
      bookLater() {
        if (window.innerWidth <= 900) {
          this.$store.commit('setSelectedStep', getters.AFTER_SIGNUP());
        } else {
          window.location.href = '/clients';
        }
      },
    },
  };
</script>

<style></style>
