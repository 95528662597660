import { createApp } from 'vue';
import { createStore } from 'vuex';
import Toaster from '@meforma/vue-toaster';
import Login from '../login/login';
import App from '../login/app';
import { createRouter, createWebHistory } from 'vue-router';
import mutations from '../stores/login-store/mutations/login-mutations';
import getters from '../stores/login-store/getters/login-getters';
import VueCountryCode from 'vue-country-code';

Vue.use(VueCountryCode);

const app = createApp(App);
app.use(Toaster);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/auth/sign_in', name: 'SigninPage', component: Login },
    { path: '/auth/sign_up', name: 'SignupPage', component: Login },
    {
      path: '/auth/complete_signup',
      name: 'CompleteSignupPage',
      component: Login,
    },
  ],
});
const store = createStore({
  state() {
    return {
      selectedStep: 'login-form', //login-form
      trialAmount: 0,
      trialDays: 0,
      requestedOrganization: null,
      currentPlan: '',
      urlParam: '',
      accountType: '',
      superOrg: '',
      freePlan: '',
      fbClickId: '',
      copyCode: '',
    };
  },
  mutations: mutations,
  getters: getters,
});
app.use(store);
app.use(router);
document.addEventListener('DOMContentLoaded', () => {
  const appContainer = document.createElement('div');
  document.body.appendChild(appContainer);
  app.mount(appContainer);
});
