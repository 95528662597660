<template>
  <div>
    <UpperNavbar></UpperNavbar>
    <div class="flex justify-center items-center flex-col">
      <p class="font-semibold text-2xl text-gray-900 mt-16 mx-auto px-8">
        Never miss a client message again
      </p>
      <div
        style="max-width: 1170px; margin-top: 61px"
        class="flex justify-center"
      >
        <div class="flex-1">
          <p class="text-gray-700 font-normal mt-6 margin-notif px-8">
            Want to be notified right on your phone whenever your client sends a
            message? Enter your phone number and we’ll text you a link to
            download the Coach Catalyst app
          </p>
          <div class="px-8 mt-6 phone-number" style="width: 325px">
            <div>
              <label
                for="phone-number"
                class="block text-sm font-medium text-gray-700"
                >Phone Number</label
              >
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 flex items-center">
                  <label for="country" class="sr-only">Country</label>
                  <vue-country-code
                    @onSelect="
                      ({ dialCode }) => {
                        phoneCode = dialCode;
                      }
                    "
                    :preferredCountries="['US', 'CA', 'GB', 'AU']"
                  >
                  </vue-country-code>
                </div>
                <input
                  maxlength="11"
                  v-model="phone"
                  type="tel"
                  size="11"
                  name="phone-number"
                  id="phone-number"
                  class="focus:ring-green-500 focus:border-green-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                  placeholder="+1 (555) 987-6543"
                />
              </div>
            </div>
          </div>
          <div class="flex w-full phone-next-flex">
            <input
              type="button"
              @click="skip"
              value="Skip"
              tabindex="4"
              class="btn button back-btn phone-skip"
            />
            <input
              type="button"
              @click="submit"
              value="Send me the link to the app"
              tabindex="4"
              class="btn button info-next"
              style="width: 250px"
            />
          </div>
        </div>
        <div class="right_side flex-1 flex justify-center items-center">
          <div class="story-background"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import UpperNavbar from '../shared/upper-navbar';
  import getters from '../../stores/login-store/getters/login-getters';
  import { mapGetters } from 'vuex';
  import apiUrl from '../../api-url';

  export default {
    name: 'send-your-number',
    data() {
      return {
        isThirdPartyScriptLoaded: false,
        phoneCode: 1,
        phone: '',
      };
    },
    components: { UpperNavbar },
    mixins: [apiUrl],
    mounted() {},
    computed: {
      ...mapGetters(['BOOK_A_CALL']),
    },
    methods: {
      skip() {
        this.$store.commit('setSelectedStep', getters.BOOK_A_CALL());
      },
      async submit() {
        let body = {
          phone: this.phoneCode + this.phone,
        };
        const response = await axios.post(
          this.organizationUrl + 'signup_phone',
          body
        );
        console.log(response);
        this.$store.commit('setSelectedStep', getters.BOOK_A_CALL());
      },
    },
  };
</script>

<style></style>
