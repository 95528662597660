<template>
  <div class="auth-navbar">
    <img
      :src="require(`images/logo_one_line.png`)"
      alt="Coach Catalyst"
      class="navbar-logo"
    />
  </div>
</template>

<script>
  export default {
    name: 'upper-navbar',
  };
</script>

<style scoped></style>
